<template>
  <v-dialog
    v-model="show"
    persistent
    max-width="500"
  >
    <v-card class="dialog-card">
      <v-card-title class="dialog-title">
        {{ title }}
      </v-card-title>
      <v-card-text class="dialog-text">
        {{ text }}
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-btn
          color="primary"
          text
          class="dialog-btn-two-text"
          @click="emitConfirm"
        >
          {{ btnText }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: 'DialogBox',
  props: {
    title: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: '',
    },
    btnText: {
      type: String,
      default: 'Enviar',
    },
    show: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    btnClass() {
      if (this.btnColor) {
        return `dialog-btn-text ${this.btnColor}`;
      }
      return 'dialog-btn-text default-color';
    },
  },
  methods: {
    emitConfirm() {
      this.$emit('confirm');
    },
  },
};
</script>
<style lang="scss">
.v-dialog {
  border-radius: 4px;

  .dialog-card {
    padding: 8px;

    .dialog-title {
      @include font-open-sans-bold;
    }

    .dialog-text {
      @include font-monteserrat-regular;
      color: $text-color-secondary;
      font-size: $font-size-extra-small;
    }

    .dialog-btn-text {
      @include font-open-sans-bold;
      font-weight: bold;
      padding: 0;
      margin-left: 0;
      &.default-color {
        color: $secondary-button-color;
      }
      &.warning-color {
        color: $warning-color;
      }
    }

    .dialog-btn-two-text {
      @include font-open-sans-bold;
      color: $text-color-secondary;
      font-weight: bold;
      padding: 0;
      margin-left: 0;
    }
  }
}
</style>
